'use client';

import { useEffect, useState } from 'react';
import { setContext as setSentryContext } from '@sentry/nextjs';
import cookie from 'js-cookie';
import { usePathname } from 'next/navigation';
import UAParser from 'ua-parser-js';

import {
  COOKIE_ALREADY_USER,
  COOKIE_DEVICE_ID,
  PICTARINE_VERSION_CODE,
} from '@/constants';
import { sendLog } from '@/services/logs';
import isSameCodeVersion from '@/utils/isSameCodeVersion';

interface Props {
  ipAddress: string | null;
}

function AttributionLogger({ ipAddress }: Props) {
  const [appMounted, setAppMounted] = useState(false);

  const isAlreadyUser = cookie.get(COOKIE_ALREADY_USER) === 'true';
  const deviceId = cookie.get(COOKIE_DEVICE_ID);
  const pathname = usePathname();

  // Todo: move this function to a utils file
  const getDefaultFontSize = () => {
    const element = document.createElement('div');
    element.style.width = '1rem';
    element.style.display = 'none';
    document.body.append(element);

    const widthMatch = window
      .getComputedStyle(element)
      .getPropertyValue('width')
      .match(/^(\d*\.?\d*)px$/);

    element.remove();

    if (!widthMatch || widthMatch.length < 1) {
      return null;
    }

    const result = Number(widthMatch[1]);
    return !Number.isNaN(result) ? result / 0.625 : null;
  };

  // Todo: move this function to a utils file
  const getPreferredColorScheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';

  // Todo: move this function to a utils file
  const getPreferredReducedMotion = () =>
    window.matchMedia('(prefers-reduced-motion: reduce)').matches
      ? 'reduce'
      : 'no-preference';

  useEffect(() => {
    if (deviceId) {
      setSentryContext('user', { deviceId });
    } else {
      // eslint-disable-next-line no-console
      console.warn('No deviceId on client');
    }
  }, [deviceId]);

  useEffect(() => {
    if (!appMounted) {
      let userState = 'install';
      const sameCodeVersion = isSameCodeVersion();
      if (isAlreadyUser) {
        if (sameCodeVersion) userState = 'classic';
        else userState = 'update';
      } else {
        sendLog('app_attribution_success', {
          referrer: document.referrer,
          location: window.location,
        });
        cookie.set(COOKIE_ALREADY_USER, 'true');
      }
      const uaParser = new UAParser();
      sendLog('app_launch_state', {
        type: userState,
        systemName: 'web',
        systemVersion: PICTARINE_VERSION_CODE.toString(),
        locale: 'en-US',
        languages: window.navigator?.languages.toString(),
        deviceSize: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
        userAgent: window.navigator.userAgent,
        os: uaParser.getOS().name,
        browser: uaParser.getBrowser().name,
        browserVersion: uaParser.getBrowser().version,
        deviceModel: uaParser.getDevice().model,
        deviceType: uaParser.getDevice().type,
        preferredColorScheme: getPreferredColorScheme(),
        preferredReducedMotion: getPreferredReducedMotion(),
        userFontSize: getDefaultFontSize(),
        offScreenCanvas: typeof OffscreenCanvas !== 'undefined',
        worker: window.Worker !== undefined,
        indexedDB: window.indexedDB !== undefined,
        hardwareConcurrency: navigator.hardwareConcurrency,
        ipAddress,
        outerWidth: window.outerWidth,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        innerHeight: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio,
        visualViewportScale: window.visualViewport?.scale,
        pathname,
      });
      setAppMounted(true);
    }
  }, [appMounted, ipAddress, isAlreadyUser, pathname]);

  return null;
}

export default AttributionLogger;
