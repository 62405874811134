'use client';

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { LANDING_PAGES_PATH } from '@/constants';
import { sendLog } from '@/services/logs';
import getBasePath from '@/utils/getBasePath';

import HistoryContext from '.';

const BACK_DISABLED_PATHS = ['/', '/tips', '/thankyou', ...LANDING_PAGES_PATH];

function HistoryProvider({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const { back } = useRouter();

  const [history, setHistory] = useState<string[]>([]);

  const asPath = pathname?.replace(`${getBasePath()}`, '') || '/';

  useEffect(() => {
    if (!asPath || history[history.length - 1] === asPath) return;
    if (history.length === 0 || asPath === '/') setHistory([asPath]);
    else setHistory((previous) => [...previous, asPath]);
  }, [asPath, history]);

  return (
    <HistoryContext.Provider
      value={useMemo(
        () => ({
          hasHistory:
            history &&
            history.length > 1 &&
            !BACK_DISABLED_PATHS.some(
              (disabledPath) => asPath === disabledPath,
            ),
          history,
          back: () => {
            if (history.length > 1) {
              sendLog('back_button_tapped', {
                from: history[history.length - 1],
              });
              setHistory((prevState) => prevState.slice(0, -2));
              back();
            }
          },
        }),
        [history, asPath, back],
      )}
    >
      {children}
    </HistoryContext.Provider>
  );
}

export default HistoryProvider;
